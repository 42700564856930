import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import DirecteurImage from '../img/Directeur/Directeur-auto.jpg'
import Qoute from '../img/icon/icone-doyen.png'


class Directeur extends Component {

    render() {

        return (
        <section className="why__area pt-50 mb-40">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div className="why__content mt-40">
                        <div className="section__title-wrapper pr-0 mb-30">
                           <h2 className="section__title">MOT <span className="yellow-bg yellow-bg-big"> DU DEAN<img src={banneryellow} alt="img not found" /></span></h2>
                              <img src={Qoute} />
                           <p className='pt-15'>
                           Le développement de l’économie marocaine a besoin de cadres aguerris aux
techniques du management, que ce soit la
finance, le marketing, la stratégie, la logistique, les ressources humaines ou le commerce international. Rabat Business School prépare
les futurs leaders en s’appuyant sur une équipe d’enseignants-chercheurs de haut niveau. Elle offre un
environnement et un cadre de travail multiculturels
exceptionnels dont la seule ambition est de garantir
l’employabilité des jeunes diplômés en leur transférant les valeurs d’engagement, de respect et de
responsabilité. Une exposition internationale permanente leur permet d’acquérir ce « petit supplément
d’âme » qui en font des managers capables de communiquer, d’agir et de décider dans un environnement globalisé. Que ce soit après un baccalauréat,
une classe préparatoire ou une licence de l’université, vous rejoindrez une grande école de management accréditée par l’AACSB, et classée dans le
prestigieux QS World University Rankings et N°1 au
Maroc et en Afrique du Nord par Jeune Afrique.
                           </p>
                           <p>
                           Dr. Olivier APTEL
                           <span className='d-block'>Directeur Général de Rabat Business School </span>
                           </p>
                        </div>
                        <div className="why__btn">
                           <a href="/contact"><a className="g-btn e-btn-3 mr-30">Rejoignez-nous</a></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                     <div className="why__thumb">
                        <img src={DirecteurImage} alt="img not found" className='w-100' />
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Directeur;
import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import logo1 from '../img/partenaire/capemini.png'
import logo2 from '../img/partenaire/IBM.png'
import logo3 from '../img/partenaire/Banque.svg'
import logo4 from '../img/partenaire/Microsoft.webp'


class Partenaire extends Component {

    render() {

        return (
        <section className="what__area pt-10">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12  col-xl-12  col-lg-12 mx-auto">
                     <div className="section__title-wrapper mb-60 text-center">
                        <h2 className="section__title">
                        Partenaire 
                           <span className="yellow-bg-big"> Académique
                           <img src={banneryellow} alt="img not found" />
                           </span>
                        </h2>
                      
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center align-items-center">
               <div className="col-xxl-3  col-xl-3 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo3} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-3  col-xl-3 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo1} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo4}alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo2}alt="img not found" />
                        </div>
                     </div>
                  </div>
                
                 
               </div>
            </div>
         </section>
        );
    }
}

export default Partenaire;